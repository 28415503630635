<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4>본인인증을 진행하세요</h4>
							</div>
						</div>
						<div class="text_guide_desc pt-15">
							<p>PIN번호 변경을 위해서 본인인증이 필요합니다</p>
						</div>
					</div>
				</div>
				<div class="intro_btn_wrap">
					<div class="btn_area">
						<button
							@click="is_pin = true"
							class="btn_l btn_fill_blue"
						>본인인증</button>
						<form
							style="display: none"
							name="form_chk" method="get">
							<input type="hidden" name="m" value="checkplusService">     <!-- 필수 데이타로, 누락하시면 안됩니다. -->
							<input type="hidden" name="EncodeData" value="">  <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
							<input type ="hidden" name="recvMethodType" value ="get">
							<!--
							<a href="javascript:fnPopup();"> CheckPlus 안심본인인증 Click</a>
							-->
						</form>
					</div>
				</div>
			</div>
		</div>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%"

			@click="pinClick"
			@cancel="pinCancel"
		></PIN>
	</div>
</template>

<script>

import PIN from "@/view/Auth/mafia007"

export default {
	name: 'mafia104'
	, props: ['Axios']
	, components: { PIN }
	, data: function(){
		return {
			program: {
				name: '나이스 본인인증'
				, not_header: true
				, not_footer: true
			}
			,account: {
				nick: ''
				, memo: ''
				, auth: ''
				, HPNO: ''
			}
			,is_pin: false
			,pin_option: {
				pin_type: 'set'
			}
		}
	}
	,methods: {

		onRequest: async function () {
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: 'https://nice.reappay.net/check-pay-plus/request'
					, header: {
						'Content-Type': 'application/json; charset=utf-8'
					}
					, isResult: true
				})

				if (result.success) {

					this.fnPopup(result.enc_data)

					let self = this

					window.addEventListener('message', function (e) {
						const call = e.data

						if (call.success) {
							self.is_pin = true

						} else {
							self.$bus.$emit('notify',  {type: 'error', message: call.resp_message})
						}
					});
				} else {
					this.$bus.$emit('notify',  {type: 'error', message: result.data.resp_message})
				}

			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  e)
			}
		}
		, fnPopup(encode) {
			window.name = "Parent_window";
			window.open('', 'popupChk', 'height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
			document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
			document.form_chk.target = "popupChk";
			document.form_chk.EncodeData.value = encode
			console.log('document.form_chk.EncodeData', document.form_chk.EncodeData.value)
			document.form_chk.submit();
		}
		,pinClick: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.update_pin
					,data: {

					}
					, name: ''
				})

				if(result.success){
					this.$emit('to', { name: 'mafia017'})
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
				if(process.env.VUE_APP_TYPE == 'sample'){
					this.$emit('to', { name: 'mafia017'})
				}
			}
			this.pin_option.pin_type = 'set'
		}
		,pinCancel: function(){
			this.is_pin = false
			this.pin_option.pin_type = 'set'
		}

	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>